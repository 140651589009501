import { combineReducers } from 'redux';
import user from './userReducer';
import containerList from './containerListReducer';
import dropdowns from './dropdownReducer';
import selectedFilters from './selectedFiltersReducer';
import vvdAllocationRep from './vvdAllocationRepReducer';
import assumeuser from './assumeuserReducer';
import containerDashboard from './containerDashboardReducer';
import bookingDashboard from './bookingDashboardReducer';
import appinfo from './appInfo';

import { withReduxStateSync } from 'redux-state-sync';

const rootReducer = combineReducers({
  appinfo,
  user,
  containerList,
  dropdowns,
  assumeuser,
  containerDashboard,
  bookingDashboard,
  vvdAllocationRep,
  selectedFilters,
});

export default withReduxStateSync(rootReducer);
